import { Form, Formik, Field as F } from 'formik';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { createFeaturedRecipe, getRecipesSearch, getFeaturedRecipes } from '../actions';

import { ReduxAction, FeaturedRecipeModalValues, RecipeList, FeaturedRecipeList } from '../types';
import { Button } from './button';
import Field from './field';

const Modal = styled.div`
  background: white;
  border: 2px black solid;
  padding: 5%;

  position: fixed;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  margin: -25% 0 0 -25%;
  z-index: 2;

  overflow: scroll;
`;

const ListItem = styled.li`
  list-style: none;
  cursor: pointer;
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 1%;
`;

const StyledForm = styled(Form)`
  p {
    margin: 0;
  }
  label {
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
    margin: 1rem 0;
  }
  button {
  }
`;

const mapStateToProps = ({ recipes }) => ({
  recipes,
});

const mapDispatchToProps = (dispatch) => ({
  getRecipesSearchAction: (search: string) => dispatch(getRecipesSearch(search)),
  createFeaturedRecipeAction: (recipeId: string, active: boolean, data: FormData) =>
    dispatch(createFeaturedRecipe(recipeId, active, data)),
  getFeaturedRecipesAction: () => dispatch(getFeaturedRecipes()),
});

interface Props {
  recipes: RecipeList;
  getRecipesSearchAction: Function;
  closeModal: () => void;
  createFeaturedRecipeAction: (recipeId: string, active: boolean, data: FormData) => ReduxAction<null>;
  getFeaturedRecipesAction: () => ReduxAction<FeaturedRecipeList>;
}

const FeaturedRecipeModal: React.FC<Props> = ({
  recipes,
  getRecipesSearchAction,
  closeModal,
  createFeaturedRecipeAction,
  getFeaturedRecipesAction,
}) => {
  const initialValues: FeaturedRecipeModalValues = {
    recipeID: '',
    title: '',
    active: false,
    files: undefined,
  };
  const [showRecipeList, setShowRecipeList] = useState<boolean>(false);

  useEffect(() => {
    getRecipesSearchAction();
  }, []);

  const handleOnChange = (e) => {
    if (e.target.value.length <= 2) {
      setShowRecipeList(false);
    }
    if (e.target.value.length >= 3) {
      setShowRecipeList(true);
      getRecipesSearchAction(e.target.value);
    }
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const onSubmit = async (body) => {
    const { recipeID, active, files } = body;

    if (!files) {
      return;
    }
    const data = new FormData();
    Array.from(files).forEach((file, i) => {
      data.append(`files[${i}]`, file);
    });
    await createFeaturedRecipeAction(recipeID, active, data);
    await getFeaturedRecipesAction();
    closeModal();
  };

  return (
    <Modal>
      <CloseButton onClick={handleCloseModal} buttonStyle="primary">
        X
      </CloseButton>
      <h1>New Featured Recipe</h1>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: { [key in keyof FeaturedRecipeModalValues]?: any } = {};
          if (!values.recipeID) {
            errors.title = 'Required';
          }
          return errors;
        }}
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting, setFieldValue, handleReset }) => (
          <StyledForm>
            {!values.title && (
              <>
                <Field title="Search for recipe" name="featuredRecipeTitle" onChange={handleOnChange} />
                {showRecipeList &&
                  recipes?.map((recipe, index) => {
                    return (
                      <ListItem
                        key={Math.random() * 1000}
                        onClick={() => {
                          const { id, title } = recipe;
                          setFieldValue('recipeID', id);
                          setFieldValue('title', title);
                          setShowRecipeList(false);
                        }}
                      >
                        {recipe.title}
                      </ListItem>
                    );
                  })}
              </>
            )}
            {values.title && (
              <>
                <p>Selected:</p>
                <h3>{values.title}</h3>
                <button onClick={handleReset}>Search again</button>
                <label>
                  <Field
                    type="checkbox"
                    name="active"
                    onChange={() => {
                      setFieldValue('active', !values.active);
                    }}
                  />
                  Active
                </label>
                <input
                  id="file"
                  name="file"
                  type="file"
                  accept="image/*"
                  multiple={false}
                  onChange={(e) => {
                    setFieldValue('files', e.currentTarget.files);
                  }}
                />
              </>
            )}

            <Button type="submit" disabled={isSubmitting || !values.title} buttonStyle="primary">
              Submit
            </Button>
          </StyledForm>
        )}
      </Formik>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedRecipeModal);
