import React from 'react';
import styled from 'styled-components';

import { Draggable } from 'react-beautiful-dnd';

import { FeaturedRecipe } from '../types';

interface styledProps {
  isActive?: boolean;
}

const StyledButton = styled.button<styledProps>`
  background-color: whitesmoke;
  width: 180px;

  border-radius: 50px;
  /* background: ${({ isActive }) => (isActive ? 'rgb(255, 199, 0);' : 'lightgrey')}; */
  background: whitesmoke;
  &:hover {
    background: ${({ isActive }) => (isActive ? 'crimson' : 'rgb(255, 199, 0);')};
  }
  span:before {
    position: relative;
    content: '${({ isActive }) => (isActive ? 'ACTIVE' : 'INACTIVE')}';
  }
  &:hover span:before {
    display: none;
  }
  &:hover span:after {
    content: '${({ isActive }) => (isActive ? 'DEACTIVATE' : 'ACTIVATE')}';
  }
`;

const DragWrapper = styled.div`
  padding: 0.2rem 1rem;
  border: 2px solid;
  margin: 1rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.2);
  * {
    margin: 0;
    padding: 0;
  }
  &.active {
    border-color: rgb(255, 199, 0);
  }

  &.inactive {
    border-color: rgba(100, 100, 100, 0.2);
    opacity: 0.8;
  }
  h2 {
    margin-right: 2rem;
  }
  p {
    margin: auto;
  }
  img {
    height: 60px;
  }
`;

interface Props {
  recipe: FeaturedRecipe;
  index: number;
  handleClick: Function;
}

const FeaturedRecipeListItem: React.FC<Props> = ({ recipe, index, handleClick }) => {
  return (
    <Draggable draggableId={`id-${recipe.id}`} index={index}>
      {(provided) => (
        <DragWrapper
          className={recipe.active ? 'active' : 'inactive'}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <h2>{index + 1}.</h2>
          <img src={recipe.imageURL} />
          <p>{recipe.title}</p>
          <StyledButton isActive={recipe.active} onClick={() => handleClick(recipe.id, recipe.active)}>
            <span></span>
          </StyledButton>
        </DragWrapper>
      )}
    </Draggable>
  );
};

export default FeaturedRecipeListItem;
