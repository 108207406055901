import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getFeaturedRecipes } from '../actions/';
import { ReduxAction, FeaturedRecipeList } from '../types';

import FeaturedRecipeModal from '../components/FeaturedRecipeModal';
import FeaturedRecipesList from '../components/FeaturedRecipesList';

const Wrapper = styled.div`
  h3 {
    margin-top: 2rem;
  }
`;

const StyledButton = styled.button`
  background-color: whitesmoke;
  width: 180px;
  border-radius: 50px;
`;

const mapStateToProps = ({ featuredRecipes }) => ({
  featuredRecipes,
});

interface Props {}

const FeaturedPage: React.FC<Props> = () => {
  const [showModal, setShowModal] = useState(false);

  const openFeaturedRecipeModal = () => {
    setShowModal(true);
  };
  const closeFeaturedRecipeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <ToastContainer />
      <Wrapper>
        <h2>Featured Recipes</h2>
        <StyledButton onClick={() => openFeaturedRecipeModal()}>New Feature</StyledButton>
        {showModal && <FeaturedRecipeModal closeModal={closeFeaturedRecipeModal} />}

        <h3>Active Features</h3>
        <p>Drag to reorder. You must submit for changes to be saved.</p>
        <FeaturedRecipesList />
      </Wrapper>
    </>
  );
};

export default connect(mapStateToProps)(FeaturedPage);
