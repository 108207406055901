import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { ReduxAction, FeaturedRecipeList, FeaturedRecipeListRecipe } from '../types';

import { updateFeaturedRecipeStatus, updateAllFeaturedRecipes, getFeaturedRecipes } from '../actions/';
import FeaturedRecipeListItem from './FeaturedRecipeListItem';

const StyledButton = styled.button`
  background-color: whitesmoke;
  width: 180px;
  border-radius: 50px;
`;

const mapStateToProps = ({ featuredRecipes }) => ({
  featuredRecipes,
});

const mapDispatchToProps = (dispatch) => ({
  getFeaturedRecipesAction: () => dispatch(getFeaturedRecipes()),
  updateAllFeaturedRecipesAction: (featuredRecipes: FeaturedRecipeList) =>
    dispatch(updateAllFeaturedRecipes(featuredRecipes)),
  updateFeaturedRecipeStatusAction: (id: string, active: boolean) => dispatch(updateFeaturedRecipeStatus(id, active)),
});

interface Props {
  featuredRecipes: FeaturedRecipeList;
  getFeaturedRecipesAction: () => ReduxAction<FeaturedRecipeList>;
  updateAllFeaturedRecipesAction: (featuredRecipes: FeaturedRecipeList) => ReduxAction<FeaturedRecipeList>;
  updateFeaturedRecipeStatusAction: (id: string, active: boolean) => ReduxAction<FeaturedRecipeListRecipe>;
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const FeaturedRecipesList: React.FC<Props> = ({
  featuredRecipes,
  getFeaturedRecipesAction,
  updateAllFeaturedRecipesAction,
  updateFeaturedRecipeStatusAction,
}) => {
  const [displayFeaturedRecipes, setDisplayFeaturedRecipes] = useState(null);

  useEffect(() => {
    getFeaturedRecipesAction();
  }, []);

  useEffect(() => {
    if (featuredRecipes) {
      setDisplayFeaturedRecipes(
        featuredRecipes.sort((recipeA, recipeB) => recipeA.displayOrder - recipeB.displayOrder)
      );
    }
  }, [featuredRecipes]);

  const handleClick = (id, active) => {
    const stateCopy = [...displayFeaturedRecipes];
    const index = stateCopy.findIndex((recipe) => {
      return recipe.id === id;
    });
    stateCopy[index].active = !active;
    updateFeaturedRecipeStatusAction(id, !active);
    setDisplayFeaturedRecipes(stateCopy);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const newOrder = reorder(displayFeaturedRecipes, result.source.index, result.destination.index);
    newOrder.forEach((recipe, index) => {
      recipe['displayOrder'] = index;
    });
    setDisplayFeaturedRecipes(newOrder);
  };
  const handleSubmit = () => {
    updateAllFeaturedRecipesAction(displayFeaturedRecipes);
  };

  return (
    displayFeaturedRecipes && (
      <>
        <StyledButton onClick={handleSubmit}>Submit changes</StyledButton>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {displayFeaturedRecipes.map((recipe, index) => {
                  return (
                    <FeaturedRecipeListItem
                      handleClick={handleClick}
                      key={recipe.recipeID}
                      recipe={recipe}
                      index={index}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </>
    )
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedRecipesList);
